import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { PageTitle } from '../components/partials/PageTitle'

export default function Office() {
  return (
    <Layout classes={'max-w-6xl mx-auto px-4'}>
      <Seo title="Office" />
      <div className="pt-16">
        <PageTitle title="Office" />
        <div className="max-w-2xl mx-auto">
          <div className="flex flex-col space-y-6 sm:space-y-0">
            <div id="contact">
              <div className="min-h-screen flex items-center justify-center">
                <div className={'w-full'}>
                  <h2 className="font-display font-bold uppercase text-3xl mb-2">
                    Contact
                  </h2>
                  <ul>
                    <li>Address: Troias 49 Voula 16673</li>
                    <li>
                      email:{' '}
                      <a href="mailto:ttousas@gmail.com" className="underline">
                        ttousas@gmail.com
                      </a>
                    </li>
                    <li>
                      Mob: <a href="tel:+306977719743">+30 697 771 9743</a>
                    </li>
                    <li>
                      Instagram:{' '}
                      <a
                        href="https://www.instagram.com/thodoris_tousas/"
                        target="_blank"
                      >
                        @thodoris_tousas
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div id="about">
              <div className="min-h-screen flex items-center justify-center">
                <div className={'w-full'}>
                  <h2 className="font-display font-bold uppercase text-3xl mb-2">
                    About
                  </h2>
                  <p>
                    Tousas architects is a Greek architectural studio dedicated
                    to the broad realm of architecture. Founded in Athens in
                    2017, the studio works both public and private commissions.
                    Tousas' built work includes "Fit Buddy" store (2020), MIA's
                    headquarters (2021), "Ξ" apartment building in Glyfada
                    (2022) and a house in Sifnos (2022).
                  </p>
                  <p>
                    Currently the studio is working on a diverse range of
                    projects across Greece
                  </p>
                  <p>
                    Prior to this, Thodoris Tousas has gained experience working
                    on projects worldwide, he has received various distinctions
                    in architectural competitions and his work has been shown
                    and published in international architectural exhibitions.
                  </p>
                </div>
              </div>
            </div>
            <div id="people">
              <div className="min-h-screen flex items-center justify-center">
                <div className={'w-full'}>
                  <h2 className="font-display font-bold uppercase text-3xl mb-2">
                    People
                  </h2>
                  <p>
                    Thodoris Tousas (*1984) studied architecture at University
                    of Thessaly, TU Delft and ETH Zurich. He gained experience
                    working on various projects In Switzerland, Brazil and
                    Greece. In 2017 he founded tousas architects in Athens.
                  </p>
                  <p>
                    Collaborators:
                    <ul>
                      <li>Flora Koraki - Architect</li>
                      <li>Yannis Karababas - Architect</li>
                      <li>Erjon Kristo - Visualisation</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
